<template>
  <h1>HELOO</h1>
  <button @click="storeUserByBuBr()" v-if="count == 2">Click</button>
  <h1>Employee Enroll time</h1>
  <div>{{ employeeEncrollTime }}</div>
  <br />
  <h1>Bu Br Enroll</h1>
  <div>
    {{ enrollByBu }}
  </div>
</template>
<script >
import axios from "axios";
export default {
  data() {
    return {
      // employees: this.$store.getters["odoo/getAllEmployees"],
      employees: [],
      count: 0,
      employee_ids: [],
      employeeEncrollTime: [],
      enrollByBu: [],
      filterArray: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllEmployee() {
      await axios
        .get("https://corehrm.umgclouds.com/api/employees")
        .then((response) => {
          this.loading = true;
          this.$Progress.start();
          console.log(response.data.data.filter((res) => res.emp_id == "P000570"))
          this.employees = response.data.data;
          this.getAllEnrollUsers();
          this.loading = false;
          this.$Progress.finish();
          // console.log(this.employees);
        });
    },
    async getAllEnrollUsers() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/enroll-list-byBuBr`)
        .then((response) => {
          this.employee_ids = response.data.data;
          console.log(this.employee_ids);
          // console.log(this.employee_ids);
          this.count = 2;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.loading = false;
          this.$Progress.finish();
        });
    },

    storeUserByBuBr() {
      console.log('empidlength ', this.employee_ids.length)
      const employeeArray = this.employee_ids.map((empId) => {
        const findData = this.employees.find(
          (emp) => emp.emp_id == empId.enrolled_user_id
        );
        if (findData) {
          return {
            id: empId.enrolled_user_id,
            bu: findData.business_unit[0].name,
          };
        }
      });
      console.log('bubr lists ', employeeArray);

      const idCounts = {};

      for (const item of employeeArray) {
        if (item) {
          const { id, bu } = item;
          if (!idCounts[id]) {
            idCounts[id] = 1;
            this.employeeEncrollTime.push({ id, count: 1, bu });
          } else {
            idCounts[id]++;
            this.employeeEncrollTime.push({ id, count: idCounts[id], bu });
          }
        }
      }

      this.buCalculate(this.employeeEncrollTime);
    },

    buCalculate(array) {
      const buCounts = {};

  for (const item of array) {
    const { bu } = item;

    if (!buCounts[bu]) {
      buCounts[bu] = 1;
    } else {
      buCounts[bu] += 1;
    }
  }
      this.enrollByBu = Object.entries(buCounts).map(([bu, buCount]) => ({ buCount, bu }));

      const total = this.enrollByBu.reduce((accumulator, currentValue) => {
  return accumulator + currentValue.buCount;
}, 0);

// let total = 0;
// this.enrollByBu.forEach(element => {
//  total += element.buCount 
// });
          console.log(total)
      
    },
  },
  created() {
    this.getAllEmployee();
  },
};
</script>